<template>
    <div class="text-area-wrapper">
        <label class="t-label">{{ labelName }}</label>
        <div class="text-area">
            <textarea
                :placeholder="placeholder"
                class="t-input"
                :class="{
                    't-input-error': error,
                }"
                :rows="rows"
                :cols="cols"
                @input="handleInput"
                ref="input"
            ></textarea>
            <div class="error-container">
                <svg
                    v-if="error"
                    class="t-input-error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#000"
                        d="M12 22c-1.978 0-3.911-.587-5.556-1.685-1.644-1.1-2.926-2.66-3.683-4.488-.757-1.827-.955-3.838-.569-5.778.386-1.94 1.338-3.722 2.737-5.12 1.398-1.399 3.18-2.351 5.12-2.737 1.94-.386 3.95-.188 5.778.57 1.827.756 3.389 2.038 4.488 3.682C21.413 8.09 22 10.022 22 12c-.003 2.651-1.057 5.193-2.932 7.068S14.65 21.998 12 22zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                    />
                </svg>
            </div>
        </div>
        <span
            v-if="error"
            class="t-error"
            :class="{
                't-error-show': error,
            }"
        >
            {{ msg }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'TextAreaBox',
    props: {
        placeholder: {
            type: String,
        },
        labelName: {
            type: String,
        },
        rows: {
            type: Number,
        },
        cols: {
            type: Number,
        },
        msg: String,
        error: Boolean,
    },
    mounted() {
       // this.focusInput()
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e.target.value)
        },
        focusInput() {
            this.$refs.input.focus()
        },
    },
}
</script>

<style lang="scss">
@import './TextAreaBox.scss';
</style>
